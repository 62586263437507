import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Pager from "../components/Pager"
import { Box, Grid, Typography } from "@material-ui/core"
import HouseCard from "../components/HouseCard"
import { nodeName } from "min-document"

export default function Projects({ data, pageContext }) {
  const { nodes } = data.allCaHousesJson

  return (
    <Layout>
      <Box marginTop={3}>
        <Typography variant="h4">All Houses</Typography>
      </Box>

      <Box marginTop={5}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {nodes.map(node => (
            <Grid item md={4} sm={6} xs={12}>
              <HouseCard
                price={node.info.Price}
                adress={node.adress.street}
                image={getImage(node.featuredImg)}
                link={`/property/` + node.id}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Pager pageContext={pageContext} />
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    allCaHousesJson(
      skip: $skip
      limit: $limit
      filter: { featuredImg: { base: { ne: null } } }
    ) {
      nodes {
        featuredImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        info {
          Price
        }
        id
        adress {
          street
        }
      }
    }
  }
`

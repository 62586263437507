import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
})

export default function HouseCard({ adress, price, image, link }) {
  const classes = useStyles()
  return (
    <Link to={link}>
      <Card className={classes.root}>
        <CardActionArea>
          <GatsbyImage image={image} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {price}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {adress}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}
